import { router } from '@inertiajs/vue3'
import { onMounted, onUnmounted } from 'vue'

export function useRouterEvent(...args: Parameters<typeof router.on>): void {
	let callback = () => {}

	onMounted(() => {
		callback = router.on(...args)
	})

	onUnmounted(callback)
}
