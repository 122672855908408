<script setup lang="ts">
	import { useElementBounding } from '@vueuse/core'
	import { ref } from 'vue'

	import Topography from '@/assets/img/topography-subtle.svg'
	import Footer from '@/components/marketing/Footer.vue'
	import Header from '@/components/marketing/Header.vue'
	import { useSeo } from '@/composables/useSeo'

	useSeo({
		title: 'TravlFi | Unlimited Pay As You Go Mobile Internet',
		description: 'Internet that travels with you, no commitments, cancel anytime.',
	})

	const headerRef = ref<HTMLElement | null>(null)
	const { height } = useElementBounding(headerRef)
</script>

<template>
	<div class="relative flex min-h-screen flex-col bg-gray-50 text-gray-700" :style="{ '--header-height': `${height}px`}">
		<Header />

		<main class="shrink-0 grow basis-0" :style="{ backgroundImage: `url('${Topography}')` }">
			<slot />
		</main>

		<Footer />
	</div>
</template>
