import { router } from '@inertiajs/vue3'
import { posthog } from 'posthog-js'
import { inject, InjectionKey, Plugin } from 'vue'

const injectionKey = Symbol('posthog') as InjectionKey<typeof posthog | null>

export function usePosthog(): typeof posthog | null {
	return inject(injectionKey) ?? null
}

export function createPosthog(): Plugin {
	return app => {
		if (import.meta.env.SSR) {
			return app.provide(injectionKey, null)
		}

		const posthogInstance = posthog.init(
			// TODO: This shouldn't be hardcoded
			'phc_xtZNXatuS4qqTn489Qc2HfVI6aUi7keWRGnfnabRDPu',
			{
				api_host: 'https://us.i.posthog.com',
				capture_pageview: false,
				debug: import.meta.env.DEV,
			},
		)

		let hasIdentified = false
		router.on('navigate', event => {
			const props = event.detail.page.props as typeof event.detail.page.props & {
				global?: App.Data.GlobalData,
			}

			if (hasIdentified) {
				if (props.global?.user === null) {
					posthog.reset()

					hasIdentified = false
				}
			} else if (typeof props.global?.user?.email === 'string') {
				posthog.identify(
					`${props.global.user.id}`,
					{ email: props.global.user.email },
				)

				hasIdentified = true
			}

			posthog.capture('$pageview', { path: event.detail.page.url })
		})

		return app.provide(
			injectionKey,
			posthogInstance ?? null,
		)
	}
}
