import { onBeforeMount, shallowRef } from 'vue'

const windowRef = shallowRef<typeof window | null>(null)

export function useWindow(): Readonly<typeof windowRef> {
	onBeforeMount(() => {
		windowRef.value = window
	})

	return windowRef
}
