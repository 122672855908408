<script setup lang="ts">
	import { Cross2Icon } from '@radix-icons/vue'
	import { DialogClose, type DialogCloseProps } from 'radix-vue'

	const props = defineProps<DialogCloseProps>()
</script>

<template>
	<DialogClose class="disabled:pointer-events-none" v-bind="props">
		<slot>
			<Cross2Icon class="h-5 w-5" />
		</slot>
	</DialogClose>
</template>
