<script setup lang="ts">
	import { useVModel } from '@vueuse/core'
	import { ClassValue } from 'clsx'

	import { cn } from '@/lib/utils'

	import { type InputVariants, inputVariants } from '.'

	const props = defineProps<{
		defaultValue?: string | number | null,
		modelValue?: string | number | null,
		class?: ClassValue,
		size?: InputVariants['size'],
	}>()

	const emit = defineEmits<{
		(e: 'update:modelValue', payload: string | number): void,
	}>()

	const modelValue = useVModel(props, 'modelValue', emit, {
		passive: true,
		defaultValue: props.defaultValue,
	})
</script>

<template>
	<input v-model="modelValue" :class="cn(inputVariants({ size }), props.class)">
</template>
