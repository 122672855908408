<script setup lang="ts">
	import { InertiaLinkProps, Link } from '@inertiajs/vue3'
	import { computed } from 'vue'

	import { useWindow } from '@/composables/useWindow'

	const props = defineProps<InertiaLinkProps & {
		inNewTab?: boolean,
	}>()

	const window = useWindow()
	const isInertia = computed(() => {
		if (props.href.startsWith('/')) {
			return true
		}

		if (window.value === null) {
			return false
		}

		return props.href.startsWith(window.value.origin)
	})

	const delegatedProps = computed(() => {
		const { inNewTab: _, ...delegated } = props

		return delegated
	})
</script>

<template>
	<Link v-if="isInertia" v-bind="delegatedProps" :target="inNewTab ? '_blank' : ''" :rel="inNewTab ? 'noopener noreferrer' : ''"><slot /></Link>
	<a v-else :href="props.href" :target="inNewTab ? '_blank' : ''" :rel="inNewTab ? 'noopener noreferrer' : ''"><slot /></a>
</template>
