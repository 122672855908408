<script setup lang="ts">
	import { ChevronDownIcon } from '@radix-icons/vue'
	import { ClassValue } from 'clsx'
	import { AccordionHeader, AccordionTrigger, type AccordionTriggerProps } from 'radix-vue'
	import { computed } from 'vue'

	import { cn } from '@/lib/utils'

	const props = defineProps<AccordionTriggerProps & { class?: ClassValue }>()

	const delegatedProps = computed(() => {
		const { class: _, ...delegated } = props

		return delegated
	})
</script>

<template>
	<AccordionHeader class="flex">
		<AccordionTrigger v-bind="delegatedProps" :class="cn('flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180', $props.class)">
			<slot />

			<slot name="icon">
				<ChevronDownIcon class="h-4 w-4 shrink-0 text-gray-500 transition-transform duration-200" />
			</slot>
		</AccordionTrigger>
	</AccordionHeader>
</template>
