import { ClassValue } from 'clsx'

import { cn } from '@/lib/utils'

export { default as Container } from './Container.vue'

export const containerPadding = (...classes: Array<ClassValue>): string => cn(
	'px-6 xl:px-8',
	...classes,
)
