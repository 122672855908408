<script setup lang="ts">
	import { ClassValue } from 'clsx'
	import { AccordionItem, type AccordionItemProps, useForwardProps } from 'radix-vue'
	import { computed } from 'vue'

	import { cn } from '@/lib/utils'

	const props = defineProps<AccordionItemProps & { class?: ClassValue }>()

	const delegatedProps = computed(() => {
		const { class: _, ...delegated } = props

		return delegated
	})

	const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
	<AccordionItem v-bind="forwardedProps" :class="cn('border-b', $props.class)">
		<slot />
	</AccordionItem>
</template>
