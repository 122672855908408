import { cva, type VariantProps } from 'class-variance-authority'

export { default as Input } from './Input.vue'

export const inputVariants = cva(
	[
		'flex w-full rounded-full border border-gray-300 bg-transparent shadow-sm transition-colors placeholder:font-medium placeholder:text-gray-500',

		// File
		'file:border-0 file:bg-transparent file:px-0 file:py-1.5 file:font-medium',

		// Focus
		'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950',

		// Disabled
		'disabled:cursor-not-allowed disabled:opacity-50',
	],
	{
		variants: {
			size: {
				default: 'h-10 px-5 py-1.5 text-base/none md:h-11 md:text-lg/5',
				lg: 'h-11 px-5 text-base/none sm:h-12.5 sm:text-lg/none',
			},
		},
		defaultVariants: {
			size: 'default',
		},
	},
)

export type InputVariants = VariantProps<typeof inputVariants>
