import '@/tailwind.css'

import { createInertiaApp } from '@inertiajs/vue3'
import { browserTracingIntegration, init, replayIntegration } from '@sentry/vue'
import { InferSeoMetaPlugin as inferSeoMetaPlugin } from '@unhead/addons'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { createSSRApp, h } from 'vue'

import { resolve } from '@/app'
import { createPosthog } from '@/composables/usePosthog'

declare global {
	interface Window {
		_pinia?: string,
	}
}

// eslint-disable-next-line no-underscore-dangle
const piniaState = window._pinia ?? null

createInertiaApp({
	resolve,

	setup({ el, App, props, plugin }) {
		const head = createHead({
			plugins: [inferSeoMetaPlugin()],
		})

		const pinia = createPinia()
		if (piniaState !== null) {
			pinia.state.value = JSON.parse(piniaState) as typeof pinia.state.value
		}

		// eslint-disable-next-line vue/require-expose, vue/component-api-style, vue/require-name-property
		const app = createSSRApp({ render: () => h(App, props) })
			.use(plugin)
			.use(head)
			.use(pinia)
			.use(createPosthog())

		if (import.meta.env.PROD) {
			init({
				app,
				dsn: 'https://d3f0bd7bdc0d403792ef691c81a313d3@o363251.ingest.sentry.io/4504652854657024',
				integrations: [
					browserTracingIntegration(),
					replayIntegration(),
				],
				tracesSampleRate: 0.01,
				replaysSessionSampleRate: 0.01,
				replaysOnErrorSampleRate: 1.0,

				ignoreErrors: [
					'[object XMLHttpRequest]',
					'Failed to load Stripe.js',
					'privy.com',
					'<unknown>',
				],
				denyUrls: [
					// Chrome extensions
					/extensions\//iu,
					/^chrome:\/\//iu,
				],
			})
		}

		const params = new URLSearchParams(window.location.search)
		const serialNumber = params.get('SN')

		if (serialNumber !== null) {
			sessionStorage.setItem('serialNumber', serialNumber)
		}

		app.mount(el)
	},
})
