<script setup lang="ts">
	import { ClassValue } from 'clsx'
	import { Primitive } from 'radix-vue'
	import { computed } from 'vue'

	import { cn } from '@/lib/utils'

	import { containerPadding } from '.'

	const props = withDefaults(
		defineProps<{
			as?: 'article' | 'aside' | 'div' | 'footer' | 'header' | 'main' | 'section',
			class?: ClassValue,
			unpadded?: boolean,
		}>(),
		{
			as: 'div',
			class: false,
			unpadded: false,
		},
	)

	const classes = computed(() => cn(
		'container mx-auto',
		!props.unpadded && containerPadding(),
		props.class,
	))
</script>

<template>
	<Primitive :as="as" :class="classes">
		<slot />
	</Primitive>
</template>
