<script setup lang="ts">
	import { ClassValue } from 'clsx'
	import { DialogTitle, type DialogTitleProps } from 'radix-vue'
	import { computed } from 'vue'

	import { cn } from '@/lib/utils'

	const props = defineProps<DialogTitleProps & { class?: ClassValue }>()

	const delegatedProps = computed(() => {
		const { class: _, ...delegated } = props

		return delegated
	})
</script>

<template>
	<DialogTitle :class="cn('text-lg font-semibold text-gray-950', $props.class)" v-bind="delegatedProps">
		<slot />
	</DialogTitle>
</template>
