<script setup lang="ts">
	import { ClassValue } from 'clsx'
	import { AccordionContent, type AccordionContentProps } from 'radix-vue'
	import { computed } from 'vue'

	import { cn } from '@/lib/utils'

	const props = defineProps<AccordionContentProps & { class?: ClassValue }>()

	const delegatedProps = computed(() => {
		const { class: _, ...delegated } = props

		return delegated
	})
</script>

<template>
	<AccordionContent v-bind="delegatedProps" class="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
		<div :class="cn('pb-4 pt-0', $props.class)">
			<slot />
		</div>
	</AccordionContent>
</template>
