import { usePage } from '@inertiajs/vue3'
import { computed, DeepReadonly, Ref } from 'vue'

type ReadonlyRef<T> = DeepReadonly<Ref<T>>

export function useGlobalData(): ReadonlyRef<App.Data.GlobalData> {
	const page = usePage<{
		global: App.Data.GlobalData,
	}>()

	return computed(() => page.props.global)
}

export function useUser(): ReadonlyRef<App.Data.GlobalData['user']> {
	const globalData = useGlobalData()

	return computed(() => globalData.value.user)
}

export function useAuthenticatedUser(): ReadonlyRef<NonNullable<App.Data.GlobalData['user']>> {
	const globalData = useGlobalData()

	return computed(() => {
		if (globalData.value.user === null) {
			throw new Error('user not authenticated')
		}

		return globalData.value.user
	})
}
